import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LegacyDataRequest } from '@standardkit/core';
import { LegacyDataResponse as DataResponse } from '@standardkit/core/dist/interfaces/legacy.data.response';
import { ColumnInterface, UiButton, UiCard, UiDataTable } from '@standardkit/ui';
import { Invite, OrganizationService } from '@werfy/data/organization';

@Component({
  selector: 'wa-pending-invites-card',
  templateUrl: 'pending-invites.card.html',
  imports: [UiButton, UiCard, UiDataTable],
  providers: [DatePipe],
})
export class PendingInvitesCard {
  @Input({ required: true }) public organizationId!: string;

  @Output() public invite: EventEmitter<void> = new EventEmitter<void>();

  public response?: DataResponse<Invite>;
  public columns: ColumnInterface<Invite>[] = [
    { key: 'email', label: 'Email' },
    { key: 'role', label: 'Role' },
    { key: 'createdAt', label: 'Invited At', format: (user: Invite): string => this.formatCreatedAt(user.createdAt) },
  ];

  private request: LegacyDataRequest<Invite> = { pagination: { offset: 0, limit: 10 } };

  constructor(
    private organizationService: OrganizationService,
    private datePipe: DatePipe,
  ) {}

  public refresh(): void {
    this.onRequest(this.request);
  }

  public onRequest(request: LegacyDataRequest<Invite>): void {
    this.request = request;
    this.organizationService.getInvites$(this.organizationId, request).subscribe({
      next: (response: DataResponse<Invite>): void => {
        this.response = response;
      },
      error: (error: unknown): void => {
        console.error(error);
      },
    });
  }

  private formatCreatedAt(createdAt: Date): string {
    return this.datePipe.transform(createdAt, 'short') as string;
  }
}
