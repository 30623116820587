export class State {
  state;
  constructor(state = "Initial" /* StateEnum.Initial */) {
    this.state = state;
  }
  onError() {
    this.state = "Error" /* StateEnum.Error */;
  }
  get isError() {
    return this.state === "Error" /* StateEnum.Error */;
  }
  onSuccess() {
    this.state = "Success" /* StateEnum.Success */;
  }
  get isSuccess() {
    return this.state === "Success" /* StateEnum.Success */;
  }
  onInitial() {
    this.state = "Initial" /* StateEnum.Initial */;
  }
  get isInitial() {
    return this.state === "Initial" /* StateEnum.Initial */;
  }
  onPending() {
    this.state = "Pending" /* StateEnum.Pending */;
  }
  get isPending() {
    return this.state === "Pending" /* StateEnum.Pending */;
  }
}