import { Injectable } from '@angular/core';
import { LegacyDataRequest, LegacyDataResponse as DataResponse } from '@standardkit/core';
import { ApiService } from '@werfy/core';
import { User } from '@werfy/data/organization';
import { Observable } from 'rxjs';
import { CreateOrganizationRequest } from './create-organization.request';
import { Invite } from './invite.interface';
import { InviteRequest } from './invite.request';
import { Organization } from './organization.interface';

const RESOURCE: Readonly<string> = 'admin/organizations';
const INVITES: Readonly<string> = 'invites';
const MEMBERS: Readonly<string> = 'users';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  constructor(private apiService: ApiService) {}

  public getOrganizations$(request: LegacyDataRequest<Organization>): Observable<DataResponse<Organization>> {
    return this.apiService.getLegacyData$<Organization>(RESOURCE, request);
  }

  public createOrganization$(request: CreateOrganizationRequest): Observable<Organization> {
    return this.apiService.post$<Organization>(RESOURCE, request);
  }

  public getOrganization$(id: string): Observable<Organization> {
    const url = `${RESOURCE}/${id}`;

    return this.apiService.get$<Organization>(url);
  }

  public getInvites$(organizationId: string, request: LegacyDataRequest<Invite>): Observable<DataResponse<Invite>> {
    const url = `${RESOURCE}/${organizationId}/${INVITES}`;

    return this.apiService.getLegacyData$<Invite>(url, request);
  }

  public invite$(organizationId: string, request: InviteRequest): Observable<void> {
    const url = `${RESOURCE}/${organizationId}/${INVITES}`;

    return this.apiService.post$(url, request);
  }

  public getMembers$(organizationId: string, request: LegacyDataRequest<User>): any {
    const url = `${RESOURCE}/${organizationId}/${MEMBERS}`;

    return this.apiService.getLegacyData$(url, request);
  }
}
