import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private _isLoggedIn: boolean = false;

  public isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  // TODO : Implement
  public login$(): Observable<void> {
    this._isLoggedIn = true;
    return of(undefined);
  }

  // TODO : Implement
  public logout$(): Observable<void> {
    this._isLoggedIn = false;
    return of(undefined);
  }
}
