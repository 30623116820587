<ui-top-bar name="Organization Detail">
  <ui-breadcrumbs [breadcrumbs]="breadcrumbs"></ui-breadcrumbs>
</ui-top-bar>

<ui-jumbotron
  *ngIf="state === 'success' && !!organization"
  [name]="organization.name"
  description="Organization"
></ui-jumbotron>

<ui-row>
  <ui-column>
    <wa-pending-invites-card
      #pendingInvitesCard
      (invite)="onInviteMember()"
      *ngIf="organization"
      [organizationId]="organization.id"
    ></wa-pending-invites-card>
  </ui-column>
</ui-row>

<ui-row>
  <ui-column>
    <wa-organization-members-card
      *ngIf="organization"
      [organizationId]="organization.id"
    ></wa-organization-members-card>
  </ui-column>
</ui-row>

<wa-invite-modal
  (closeModal)="onCloseInviteModal()"
  (userInvited)="onUserInvited()"
  *ngIf="organization && showInviteModal"
  [organizationId]="organization.id"
></wa-invite-modal>
