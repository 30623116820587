import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import {
  NavigationItemInterface,
  SkHeaderModule,
  SkSidebarModule,
  SkSidebarPageModule,
  SkUserMenuModule,
  UserMenuItemInterface,
} from '@standardkit/ui';
import { AuthenticationService } from '@werfy/data/authentication';

@Component({
  imports: [SkSidebarPageModule, RouterOutlet, SkSidebarModule, SkHeaderModule, SkUserMenuModule],
  templateUrl: 'sidebar.layout.html',
})
export class SidebarLayout {
  public navigationItems: NavigationItemInterface[] = [{ icon: 'house', name: 'Dashboard', route: '', isExact: true }];
  public menuItems: UserMenuItemInterface[] = [{ icon: 'power', name: 'Log out', action: () => this.logout() }];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {}

  private logout(): void {
    this.authenticationService.logout$().subscribe(() => {
      this.router.navigate(['/'], { onSameUrlNavigation: 'reload' });
    });
  }
}
