import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LegacyDataRequest, LegacyDataResponse } from '@standardkit/core';
import { ColumnInterface, UiCard, UiDataTable } from '@standardkit/ui';
import { OrganizationService, User } from '@werfy/data/organization';

@Component({
  selector: 'wa-organization-members-card',
  templateUrl: 'organization-members.card.html',
  imports: [UiCard, UiDataTable],
  providers: [DatePipe],
})
export class OrganizationMembersCard {
  @Input({ required: true }) public organizationId!: string;

  // TODO : Name - Role(s?)
  public columns: ColumnInterface<User>[] = [
    { key: 'firstName', label: 'First name' },
    { key: 'lastName', label: 'Last name' },
    { key: 'createdAt', label: 'Date Joined', format: (user: User): string => this.formatCreatedAt(user.createdAt) },
  ];

  public response?: LegacyDataResponse<User>;

  constructor(
    private organizationService: OrganizationService,
    private datePipe: DatePipe,
  ) {}

  public onRequest(request: LegacyDataRequest<User>): void {
    this.organizationService
      .getMembers$(this.organizationId, request)
      .subscribe((response: LegacyDataResponse<User>) => {
        this.response = response;
      });
  }

  private formatCreatedAt(createdAt: Date): string {
    return this.datePipe.transform(createdAt, 'short') as string;
  }
}
