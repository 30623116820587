import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SkButtonModule, SkCardModule } from '@standardkit/ui';
import { AuthenticationService } from '@werfy/data/authentication';

@Component({
  imports: [SkCardModule, SkButtonModule],
  templateUrl: 'login.page.html',
})
export class LoginPage {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {}

  public onLogin(): void {
    this.authenticationService.login$().subscribe((): void => {
      this.router.navigate(['/'], { onSameUrlNavigation: 'reload' });
    });
  }
}
