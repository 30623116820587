<ui-modal (closeModal)="onClose()" [containsOverflow]="true" name="Add Organization">
  <ui-form [formGroup]="form">
    <ui-field name="name">
      <ui-label>Name</ui-label>
      <ui-text-input formControlName="name">
        <ng-container slot="error">Organization name is required</ng-container>
      </ui-text-input>
    </ui-field>
  </ui-form>

  <ui-bar align="space-between" slot="footer">
    <ui-button (buttonClick)="onClose()" alignIcon="left" icon="angle-left" type="neutral">Cancel</ui-button>
    <ui-button (buttonClick)="onSubmit()" icon="building">Add Organization</ui-button>
  </ui-bar>
</ui-modal>
