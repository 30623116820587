import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { State, StateType, UiBar, UiButton, UiField, UiForm, UiLabel, UiModal, UiTextInput } from '@standardkit/ui';
import { OrganizationService } from '@werfy/data/organization';

@Component({
  selector: 'wa-add-organization-modal',
  templateUrl: 'add-organization.modal.html',
  imports: [UiButton, UiForm, ReactiveFormsModule, UiModal, UiField, UiLabel, UiTextInput, UiBar],
})
export class AddOrganizationModal {
  @Output() public closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() public organizationAdded: EventEmitter<void> = new EventEmitter<void>();

  public form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });

  public state: StateType = State.Initial;

  constructor(private organizationService: OrganizationService) {}

  public onClose(): void {
    this.closeModal.emit();
  }

  public onSubmit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.state = State.Pending;
    this.organizationService.createOrganization$(this.form.value).subscribe({
      next: () => {
        this.state = State.Success;
        this.organizationAdded.emit();
      },
      error: () => {
        this.state = State.Error;
      },
    });
  }
}
