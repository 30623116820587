import { Routes } from '@angular/router';
import { LoggedInGuard, NotLoggedInGuard } from './guards';
import { CardLayout, SidebarLayout } from './layouts';
import { DashboardPage, LoginPage } from './pages';

export const routes: Routes = [
  { path: '', component: CardLayout, canMatch: [NotLoggedInGuard], children: [{ path: '', component: LoginPage }] },
  {
    path: '',
    component: SidebarLayout,
    canMatch: [LoggedInGuard],
    children: [{ path: '', component: DashboardPage }],
  },
];
