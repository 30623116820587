import { Injectable } from '@angular/core';
import { CanMatch, GuardResult, MaybeAsync } from '@angular/router';
import { AuthenticationService } from '../data/authentication';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanMatch {
  constructor(private authenticationService: AuthenticationService) {}

  public canMatch(): MaybeAsync<GuardResult> {
    return this.authenticationService.isLoggedIn();
  }
}
